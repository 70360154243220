<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card class="mx-auto" outlined>
          <v-card-title class="card-title">
            <img alt="Logo" src="../assets/stru_logo.png"/>
            <v-card-subtitle><h1>Профиль<br>пользователя</h1></v-card-subtitle>
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader v-if="state === 'loading'" type="list-item-avatar-three-line"></v-skeleton-loader>
            <v-container v-if="state !== 'loading' && !profile.uuid">
              Эта ссылка больше недействительна, но вы можете <a href="./">создать новую</a>
            </v-container>
            <v-container v-if="state !== 'loading' && profile.uuid">
              <v-row>
                <h1>E-mail: {{ profile.email }}</h1>
              </v-row>
              <v-row class="my-4">
                <v-card outlined>
                  <v-card-title>Заявки на участие в тренингах (новая эра)</v-card-title>
                  <v-card-text>
                    <p v-if="(profile.submissions || []).length === 0">Не найдены</p>
                    <v-data-table v-if="(profile.submissions || []).length > 0" :headers="submissionHeaders" :items="profile.submissions" dense></v-data-table>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="my-4">
                <v-card outlined>
                  <v-card-title>Заявки на участие в тренингах (старая эра)</v-card-title>
                  <v-card-text>
                    <p v-if="(profile.registrations || []).length === 0">Не найдены</p>
                    <v-data-table v-if="(profile.registrations || []).length > 0" :headers="registrationHeaders" :items="profile.registrations" dense></v-data-table>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="my-4">
                <v-card outlined>
                  <v-card-title>Заказы в магазине https://software-testing.ru/buy/</v-card-title>
                  <v-card-text>
                    <p v-if="(profile.shops.barancev || []).length === 0">Не найдены</p>
                    <v-data-table v-if="(profile.shops.barancev || []).length > 0" :headers="shopHeaders" :items="ordersBarancev" dense></v-data-table>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="my-4">
                <v-card outlined>
                  <v-card-title>Заказы в магазине https://testbase.ru/buy/</v-card-title>
                  <v-card-text>
                    <p v-if="(profile.shops.nazina || []).length === 0">Не найдены</p>
                    <v-data-table v-if="(profile.shops.nazina || []).length > 0" :headers="shopHeaders" :items="ordersNazina" class="elevation-1" dense></v-data-table>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="my-4">
                <v-card outlined>
                  <v-card-title>Учетная запись в форуме</v-card-title>
                  <v-card-text>
                    <p v-if="!profile.forumProfile">Не найдена</p>
                    <v-container v-if="profile.forumProfile" class="px-0">
                      <a :href="`https://software-testing.ru/forum/index.php?/user/${profile.forumProfile.member_id}`" target="_blank">{{ profile.forumProfile.name }}</a>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row class="mt-4">
                <v-card outlined>
                  <v-card-title>Учетная запись в СДО</v-card-title>
                  <v-card-text>
                    <p v-if="!profile.lms">Не найдена</p>
                    <v-container v-if="profile.lms" class="px-0">
                      <v-img :src="profile.lms.profileimageurlsmall" width="32"></v-img>
                      <a :href="`https://software-testing.ru/lms/user/profile.php?id=${profile.lms.id}`" target="_blank">{{ profile.lms.fullname }} ({{ profile.lms.username }})</a>
                      <p v-if="profile.lms.courses.length === 0">Нет подключенных курсов</p>
                      <v-container class="px-0">
                        <h3>Подключен к курсам:</h3>
                        <ul>
                          <li v-for="course in profile.lms.courses" :key="course.id">
                            <a :href="`https://software-testing.ru/lms/course/view.php?id=${course.id}`" target="_blank">{{ course.fullname }}</a>
                          </li>
                        </ul>
                      </v-container>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
require('dotenv').config()

export default {
  name: 'Profile',
  components: {
  },
  props: {
    uuid: String
  },
  data: function () {
    return {
      state: 'initial',
      profile: {},
      submissionHeaders: [
        { text: 'Дата', value: 'date' },
        { text: 'Тренинг', value: 'courseTitle' }
      ],
      registrationHeaders: [
        { text: 'Дата', value: 'date' },
        { text: 'Тренинг', value: 'title' }
      ],
      shopHeaders: [
        { text: 'Номер', value: 'order_number' },
        { text: 'Дата', value: 'order_date' },
        { text: 'Сумма', value: 'order_total' },
        { text: 'Статус', value: 'order_status' },
        { text: 'Способ оплаты', value: 'payment_method_id' },
        { text: 'Тренинги', value: 'product' },
      ]
    }
  },
  computed: {
    ordersBarancev() {
      return this.profile.shops ? this.orders(this.profile.shops.barancev) : []
    },
    ordersNazina() {
      return this.profile.shops ? this.orders(this.profile.shops.nazina) : []
    }
  },
  methods: {
    orders(items) {
      const result = new Map()
      for (const item of items) {
        const product = `${item.product_name} x ${item.product_quantity}`
        if (result.has(item.order_number)) {
          const order = result.get(item.order_number)
          order.product = `${order.product}, ${product}`
        } else {
          result.set(item.order_number, { ...item, product: product })
        }
      }
      return Array.from(result.values())
    }
  },
  async mounted() {
    this.state = 'loading'
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/profile/${this.uuid}`)
    this.profile = response.data
    this.state = 'ready'
    document.title = 'Профиль пользователя ' + this.profile.email
  }
}
</script>
